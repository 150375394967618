import { IBrandConstants } from "@/models/IBrandConstants";
export const brandConstants: IBrandConstants = {
  PARTNER_CODE: "KOKATTO",
  PARTNER_NAME: "Kokatto",
  PARTNER_NAME_TEXT: "Kokatto",
  PARTNER_BRAND_NAME: "Kokatto",
  PARTNER_PREFIX_EMAIL: "",
  DEFAULT_LANGUAGE: "en",

  PARTNER_BILLEDTYPE_CONFIGURABLE: false,
  PARTNER_BILLEDTYPE: "postpaid",
  PARTNER_PACKAGABLE: false,

  HAVE_FORGOT_PASSWORD: true,
  HANDLE_FORGOT_PASSWORD: true,
  FORGOT_PASSWORD_LINK: "",
  HAVE_REGISTER: false,
  HANDLE_REGISTER: false,
  REGISTER_LINK: "",
  PARTNER_CUSTOMER_SERVICE_EMAIL: "support@kokatto.com",
  PARTNER_BUSINESS_EMAIL: "support@kokatto.com",
  CREATE_USER_FORM_IS_SHOW_PASSWORD_INPUT_TEXT: true,
  CREATE_USER_FORM_IS_SHOW_EMAIL_INPUT_TEXT: true,
  CREATE_USER_FORM_IS_SHOW_EMAIL_LIST: false,
  CREATE_USER_FORM_IS_SHOW_EMAIL_LABEL: false,
  CREATE_USER_FORM_IS_SHOW_GROUP_INPUT: true,
  IS_WABA_REQUIRED: true,
  IS_MULTIPLE_PACKAGE_SUBSCRIPTION: false,
  GET_MP_CHANNEL_ALL_SUBSCRIPTION: false,
  IS_BRAZE_ACTIVE: false,
  IS_FBM_ACTIVE: true,
  DEFAULT_REDIRECT_PATH: "/dashboard",
  MASK_PREMIUM_STATUS_AS: "",
  IS_STATUS_CHIP_OUTLINED: true,
  LOGIN_FORM_SUBMIT_ERROR_RESOURCE: "$vuetify.loginFormSubmitErrorAlt",
  CREATE_USER_FORM_ADD_USER_BANNER_RESOURCE: "$vuetify.settingAdminAddSuccess",
  CREATE_USER_FORM_USER_MAX_LIMIT_BANNER_RESOURCE:
    "$vuetify.settingAdminAddMax",
  CREATE_USER_FORM_CS_ABILITY_DESCRIPTION_RESOURCE:
    "$vuetify.settingAdminRoleCSInfo",
  SETTING_ADMIN_USER_ACCESS_LIMIT_RESOURCE:
    "$vuetify.partnerSettingAdminInfoAlt",
  FREE_ACCOUNT_OVERLAY_RESOURCE: "$vuetify.freeAccountSubscribeAlt2",
  FREE_ACCOUNT_OVERLAY_ACTION_RESOURCE: "$vuetify.freeAccountSubscribeAction",
  BASE_URL_PRODUCTION: "https://plus.koalaapp.id",
  EDIT_USER_FORM_IS_ROLE_EDITABLE: false,
  IS_STORE_INFO_HANDLED: true,
  ALLOWED_MEDIA_PLATFORM: [
    "WAP",
    "IGM",
    "MSG",
    "TPD",
    "LZD",
    "SHP",
    "VBR",
    "LNE",
    "TLG",
    "LVC",
    "EML",
  ],
  PREFILL_NAME_WITH_EMAIL: false,
  IS_AUTO_SUBCRIBE_AFTER_REGISTRATION: false,
  LOGIN_PAGE_ILLUSTRATION_HEADER_RESOURCE: "$vuetify.emptyString",
  LOGIN_PAGE_ILLUSTRATION_SUBTITLE_RESOURCE: "$vuetify.emptyString",
  CUSTOM_WORDINGS: {
    EN: {
      loginAgreement: "{1}",
      agreementName: "Privacy Policy",
      formNewMessageNoTemplateContent:
        'Please contact admin to add new templates. If you need assistance, send a message via email to <a style="color:#E50505; text-decoration:none;" href="mailto:support@kokatto.com" target="_blank">support@kokatto.com</a>  or ask to our sales team for assistance.',
      broadcastDetailSentConfirmSubtitlePrepaid:
        "You will send broadcast message to <b>{0} contact(s)</b> via {1}.",
      broadcastScheduledDetailSentConfirmSubtitlePrepaid:
        "You will send broadcast message to <b>{0} contact(s)</b> via {1} on {2} at {3}.",
      botLoadingText: "Kokatto Assistant is typing...",
      botEmptyContent:
        "Write your message and Kokatto Assistant will help you finding idea",
      botTextFieldPlaceHolder: "Please tell Kokatto Assistant what do you need",
      formFollowUpNoTemplateContent:
        'Please contact admin to add new templates. if you need assistance, send a email to <a style="color:#E50505; text-decoration:none;" href="mailto:support@kokatto.com" target="_blank">support@kokatto.com</a>',
      coachmarkButtonAddAdminSetting: "Add user accounts based on each role.",
      templateContentInfoLinkVariableContact:
        "https://kokatto.com/en-en/blog/469-global-122549-cara-untuk-menambahkan-data-variabel-kontak-dan-menambahkan-kontak-di-kokatto",
      loginFormSubmitErrorAlt:
        "Email, password or region is invalid. Make sure your email registered on Kokatto",
      emailCreatePreviewDomain: "@kokatto.com",
    },
    ID: {
      loginAgreement: "{1}",
      agreementName: "Kebijakan privasi",
      formNewMessageNoTemplateContent:
        'Silakan hubungi admin toko untuk menambahkan template. Jika kamu memiliki kendala, silakan kirim pesan ke <a style="color:#E50505; text-decoration:none;" href="mailto:support@kokatto.com" target="_blank">support@kokatto.com</a> atau hubungi tim sales terkait.',
      broadcastDetailSentConfirmSubtitlePrepaid:
        "Kamu akan mengirim broadcast ke <b>{0} kontak</b> via {1}.",
      broadcastScheduledDetailSentConfirmSubtitlePrepaid:
        "Kamu akan mengirim broadcast ke <b>{0} kontak</b> via {1} pada tanggal {2} jam {3}.",
      botEmptyContent:
        "Tulis pesan dan Asisten Kokatto akan membantumu menemukan ide",
      botLoadingText: "Asisten Kokatto sedang mengetik...",
      botTextFieldPlaceHolder:
        "Infokan kepada Asisten Kokatto apa yang bisa dibantu",
      formFollowUpNoTemplateContent:
        'Silakan hubungi admin toko untuk menambahkan template. Jika kamu memiliki kendala, silakan kirim pesan ke <a style="color:#E50505; text-decoration:none;" href="mailto:support@kokatto.com" target="_blank">support@kokatto.com</a> atau hubungi tim sales terkait.',
      coachmarkButtonAddAdminSetting:
        "Tambah pengguna akun sesuai dengan peranan masing-masing.",
      templateContentInfoLinkVariableContact:
        "https://kokatto.com/en-en/blog/469-global-122549-cara-untuk-menambahkan-data-variabel-kontak-dan-menambahkan-kontak-di-kokatto",
      loginFormSubmitErrorAlt:
        "Email, password atau region tidak sesuai. Pastikan email kamu sudah terdaftar di Kokatto",
      emailCreatePreviewDomain: "@kokatto.com",
    },
  },
  SHOW_CHATROOM_SEND_NEW_MESSAGE: true,
  LOGIN_AGREEMENT_LINK: "https://portal.kokatto.com/pp.html",
  SHOW_SUPPORTED_BY: false,
  SHOW_NEW_BADGE_UNTIL: {
    menuCSPerformance: 1687712400000,
    menuSocial: 1722445199000,
  },
  METABASE_QUESTION_IDS: {
    ID: {
      agentStatusQuestionId: "342",
      chatPerformanceQuestionId: "338",
      analysisQuestionId: "341",
      agentPerformanceQuestionId: "337",
      broadcastReportDashboardQuestionId: "389",
      broadcastReportHistoryQuestionId: "2022",
      broadcastReportHistoryDetailQuestionId: "2023",
      customerSatisfactionQuestionId: "391",
      broadcastHistoryChannelQuestionId: "2437",
      broadcastHistoryBroadcastOtpQuestionId: "2478",
      broadcastHistoryFollowUpQuestionId: "2479",
    },
    EN: {
      agentStatusQuestionId: "383",
      chatPerformanceQuestionId: "384",
      analysisQuestionId: "382",
      agentPerformanceQuestionId: "385",
      broadcastReportDashboardQuestionId: "389",
      broadcastReportHistoryQuestionId: "2022",
      broadcastReportHistoryDetailQuestionId: "2023",
      customerSatisfactionQuestionId: "390",
      broadcastHistoryChannelQuestionId: "2437",
      broadcastHistoryBroadcastOtpQuestionId: "2478",
      broadcastHistoryFollowUpQuestionId: "2479",
    },
  },
  CONNECT_TO_WHATSAPP_UNOFFICIAL: false,
  SHOW_CREDIT_BONUS_BANNER: false,
  SHOW_WEBSTORE_DASHBOARD: true,
  SHOW_OPENING_HOUR: true,
  SHOW_DELIVERY_SETTING: true,
  SHOW_BUSINESS_MODE_SETTING: false,
  SETTING_PAGE_PHONE_UPDATE_DESCRIPTION_RESOURCE:
    "$vuetify.storeSettingContactPhoneUpdateAlt1",
  SETTING_PAGE_WABA_UPDATE_DESCRIPTION_RESOURCE:
    "$vuetify.storeSettingContactWABAUpdateAlt1",
  SETTING_PAGE_EMAIL_UPDATE_DESCRIPTION_RESOURCE:
    "$vuetify.storeSettingContactEmailUpdateAlt1",
  PARTNER_ADMIN_LOCKABLE: false,
  SETTING_DASHBOARD_UPGRADE_TITLE_RESOURCE: "$vuetify.dashboardUpgradeTitleAlt",
  SETTING_DASHBOARD_UPGRADE_DESC_RESOURCE:
    "$vuetify.dashboardUpgradeDescriptionAlt",
  HIDE_DASHBOARD_FEATURE_BUTTON: {
    BROADCAST: false,
    CHATBOT: true,
    CS: false,
  },
  CONNECT_TO_SHOPEE: true,
  HAS_CONNECTED_CHANNEL_LIMITATION: false,
  CONNECT_TO_VIBER: true,
  SHOW_VARIABLE_LEARN_MORE_BUTTON: false,
  CONNECT_TO_LINE: true,
  CONNECT_TO_TELEGRAM: true,
  SHOW_REPORT_PAGE: true,
  SHOW_CUSTOMER_SATISFACTION_PERFORMANCE: true,
  SHOW_SURVEY_RATING: true,
  SHOW_OUTBOUND_PRICE_ALERT: false,
  API_BASE_URL: {
    production: "https://portal.kokatto.com/api/v1",
    development: "https://portal-beta.kokatto.net/api/v1",
  },
  COACHMARK_SHOW_ON_TIER: "premium",
  COACHMARK_REDIRECT_PATH: "/dashboard",
  SHOW_BROADCAST_REPORT: true,
  NEW_FEATURE_BANNER: false,
  CRM_CUSTOM_FIELD_GUIDELINE_URL:
    "https://kokatto.com/en-en/blog/469-global-122549-cara-untuk-menambahkan-data-variabel-kontak-dan-menambahkan-kontak-di-kokatto",
  CONNECT_TO_LIVECHAT: true,
  CAN_SELECT_REGION: true,
  CUSTOMER_SERVICE_LINK:
    "https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@kokatto.com",
  USER_CONSENT_AGREEMENT_LINK:
    "https://koala-asset.s3.ap-southeast-3.amazonaws.com/public/pt_kokatto_teknologi_global.html",
  USER_CONSENT_AGREEMENT_SHOW: true,
};
