import { AbilityDefinitionModel } from "@/models/AbilityDefinitionModel";
import { AbilityHelper } from "@/helpers/AbilityHelper";
import { PermissionModel } from "@/models/PermissionModel";
import { AbilityDataModel } from "@/models/AbilityDataModel";

export const allPermission: PermissionModel[] = [
  // * Group MISC
  { action: "READ", target: "DASHBOARD", commonAccess: true },
  { action: "READ", target: "HELP_CENTER", commonAccess: true },
  { action: "CREATE", target: "HEADER_CONTACT_CS", commonAccess: true },
  // ? Download Manual only Shown in SmartChat

  /**
   * * PRODUCT Group
   */
  {
    action: "READ",
    target: "CREATE_PRODUCT",
    allowedCountries: ["ID"],
    clientPermissionIndex: 12,
    userPermissionIndex: 6,
  },
  {
    action: "CREATE",
    target: "PRODUCT",
    allowedCountries: ["ID"],
    clientPermissionIndex: 12,
    userPermissionIndex: 6,
  },
  {
    action: "READ",
    target: "PRODUCT",
    allowedCountries: ["ID"],
    clientPermissionIndex: 12,
    userPermissionIndex: 7,
  },
  {
    action: "ACTIVATE",
    target: "PRODUCT",
    allowedCountries: ["ID"],
    clientPermissionIndex: 12,
    userPermissionIndex: 7,
  },
  {
    action: "UPDATE",
    target: "PRODUCT",
    allowedCountries: ["ID"],
    clientPermissionIndex: 12,
    userPermissionIndex: 7,
  },
  {
    action: "DELETE",
    target: "PRODUCT",
    allowedCountries: ["ID"],
    clientPermissionIndex: 12,
    userPermissionIndex: 7,
  },
  {
    action: "READ",
    target: "CATEGORY",
    allowedCountries: ["ID"],
    clientPermissionIndex: 12,
    userPermissionIndex: 8,
  },
  {
    action: "CREATE",
    target: "CATEGORY",
    allowedCountries: ["ID"],
    clientPermissionIndex: 12,
    userPermissionIndex: 8,
  },
  {
    action: "UPDATE",
    target: "CATEGORY",
    allowedCountries: ["ID"],
    clientPermissionIndex: 12,
    userPermissionIndex: 8,
  },
  {
    action: "DELETE",
    target: "CATEGORY",
    allowedCountries: ["ID"],
    clientPermissionIndex: 12,
    userPermissionIndex: 8,
  },
  /**
   * * TRANSACTION Group
   */
  {
    action: "READ",
    target: "TRANSACTION",
    allowedCountries: ["ID"],
    clientPermissionIndex: 13,
    userPermissionIndex: 9,
  },
  {
    action: "CREATE",
    target: "TRANSACTION",
    allowedCountries: ["ID"],
    clientPermissionIndex: 13,
    userPermissionIndex: 9,
  },
  {
    action: "UPDATE",
    target: "TRANSACTION",
    allowedCountries: ["ID"],
    clientPermissionIndex: 13,
    userPermissionIndex: 9,
  },
  {
    action: "DELETE",
    target: "TRANSACTION",
    allowedCountries: ["ID"],
    clientPermissionIndex: 13,
    userPermissionIndex: 9,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_TRANSACTION_BUTTON_CREATE",
    clientPermissionIndex: 13,
    userPermissionIndex: 9,
  },
  /**
   * * WHATSAPP Group
   */
  {
    action: "READ",
    target: "WHATSAPP_TEMPLATE",
    userPermissionIndex: 4,
    clientPermissionIndex: 14,
  },
  {
    action: "CREATE",
    target: "WHATSAPP_TEMPLATE",
    userPermissionIndex: 4,
    clientPermissionIndex: 14,
  },
  {
    action: "UPDATE",
    target: "WHATSAPP_TEMPLATE",
    userPermissionIndex: 4,
    clientPermissionIndex: 14,
  },
  {
    action: "DELETE",
    target: "WHATSAPP_TEMPLATE",
    userPermissionIndex: 4,
    clientPermissionIndex: 14,
  },
  {
    action: "READ",
    target: "WHATSAPP_BROADCAST",
    userPermissionIndex: 3,
    clientPermissionIndex: 14,
  },
  {
    action: "CREATE",
    target: "WHATSAPP_BROADCAST",
    userPermissionIndex: 3,
    clientPermissionIndex: 14,
  },
  {
    action: "UPDATE",
    target: "WHATSAPP_BROADCAST",
    userPermissionIndex: 3,
    clientPermissionIndex: 14,
  },
  {
    action: "DELETE",
    target: "WHATSAPP_BROADCAST",
    userPermissionIndex: 3,
    clientPermissionIndex: 14,
  },
  // ? Chatbot only shown in K+
  /**
   * * VIBER Group
   */
  {
    action: "READ",
    target: "VIBER_TEMPLATE",
    clientPermissionIndex: 15,
    userPermissionIndex: 21,
  },
  {
    action: "CREATE",
    target: "VIBER_TEMPLATE",
    clientPermissionIndex: 15,
    userPermissionIndex: 21,
  },
  {
    action: "UPDATE",
    target: "VIBER_TEMPLATE",
    clientPermissionIndex: 15,
    userPermissionIndex: 21,
  },
  {
    action: "DELETE",
    target: "VIBER_TEMPLATE",
    clientPermissionIndex: 15,
    userPermissionIndex: 21,
  },
  {
    action: "READ",
    target: "VIBER_BROADCAST",
    clientPermissionIndex: 15,
    userPermissionIndex: 20,
  },
  {
    action: "CREATE",
    target: "VIBER_BROADCAST",
    clientPermissionIndex: 15,
    userPermissionIndex: 20,
  },
  {
    action: "UPDATE",
    target: "VIBER_BROADCAST",
    clientPermissionIndex: 15,
    userPermissionIndex: 20,
  },
  {
    action: "DELETE",
    target: "VIBER_BROADCAST",
    clientPermissionIndex: 15,
    userPermissionIndex: 20,
  },
  /**
   * * SOCIAL Group
   */
  {
    action: "READ",
    target: "SOCIAL_PUBLISH",
    clientPermissionIndex: 16,
    userPermissionIndex: 24,
  },
  {
    action: "CREATE",
    target: "SOCIAL_PUBLISH",
    clientPermissionIndex: 16,
    userPermissionIndex: 24,
  },
  {
    action: "UPDATE",
    target: "SOCIAL_PUBLISH",
    clientPermissionIndex: 16,
    userPermissionIndex: 24,
  },
  {
    action: "DELETE",
    target: "SOCIAL_PUBLISH",
    clientPermissionIndex: 16,
    userPermissionIndex: 24,
  },
  {
    action: "READ",
    target: "SOCIAL_COMMENT",
    clientPermissionIndex: 16,
    userPermissionIndex: 25,
  },
  {
    action: "CREATE",
    target: "SOCIAL_COMMENT",
    clientPermissionIndex: 16,
    userPermissionIndex: 25,
  },
  {
    action: "UPDATE",
    target: "SOCIAL_COMMENT",
    clientPermissionIndex: 16,
    userPermissionIndex: 25,
  },
  {
    action: "DELETE",
    target: "SOCIAL_COMMENT",
    clientPermissionIndex: 16,
    userPermissionIndex: 25,
  },
  /**
   * * CUSTOMER SERVICE Group
   */
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_INBOX",
    clientPermissionIndex: 17,
    userPermissionIndex: 1,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_INBOX",
    clientPermissionIndex: 17,
    userPermissionIndex: 1,
  },
  {
    action: "UPDATE",
    target: "CUSTOMER_SERVICE_INBOX",
    clientPermissionIndex: 17,
    userPermissionIndex: 1,
  },
  {
    action: "DELETE",
    target: "CUSTOMER_SERVICE_INBOX",
    clientPermissionIndex: 17,
    userPermissionIndex: 1,
  },
  // ? CUSTOMER_SERVICE_INBOX_CHANNEL_FILTER_BUTTON_CONNECT Not Shown in KKT NEO
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_HISTORY",
    clientPermissionIndex: 17,
    userPermissionIndex: 2,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_HISTORY",
    clientPermissionIndex: 17,
    userPermissionIndex: 2,
  },
  {
    action: "UPDATE",
    target: "CUSTOMER_SERVICE_HISTORY",
    clientPermissionIndex: 17,
    userPermissionIndex: 2,
  },
  {
    action: "DELETE",
    target: "CUSTOMER_SERVICE_HISTORY",
    clientPermissionIndex: 17,
    userPermissionIndex: 2,
  },
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_SETTING",
    clientPermissionIndex: 17,
    userPermissionIndex: 0,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_SETTING",
    clientPermissionIndex: 17,
    userPermissionIndex: 0,
  },
  {
    action: "UPDATE",
    target: "CUSTOMER_SERVICE_SETTING",
    clientPermissionIndex: 17,
    userPermissionIndex: 0,
  },
  {
    action: "DELETE",
    target: "CUSTOMER_SERVICE_SETTING",
    clientPermissionIndex: 17,
    userPermissionIndex: 0,
  },
  /**
   * * CALL CENTER Group
   */
  {
    action: "USE",
    target: "CALL_CENTER",
    clientPermissionIndex: 11,
    userPermissionIndex: 22,
  },
  {
    action: "READ",
    target: "CALL_CENTER",
    clientPermissionIndex: 11,
    userPermissionIndex: 22,
  },
  {
    action: "CREATE",
    target: "CALL_CENTER",
    clientPermissionIndex: 11,
    userPermissionIndex: 22,
  },
  {
    action: "UPDATE",
    target: "CALL_CENTER",
    clientPermissionIndex: 11,
    userPermissionIndex: 22,
  },
  {
    action: "DELETE",
    target: "CALL_CENTER",
    clientPermissionIndex: 11,
    userPermissionIndex: 22,
  },
  {
    action: "READ",
    target: "CALL_CENTER_HISTORY",
    clientPermissionIndex: 11,
    userPermissionIndex: 23,
  },
  {
    action: "CREATE",
    target: "CALL_CENTER_HISTORY",
    clientPermissionIndex: 11,
    userPermissionIndex: 23,
  },
  {
    action: "UPDATE",
    target: "CALL_CENTER_HISTORY",
    clientPermissionIndex: 11,
    userPermissionIndex: 23,
  },
  {
    action: "DELETE",
    target: "CALL_CENTER_HISTORY",
    clientPermissionIndex: 11,
    userPermissionIndex: 23,
  },
  /**
   * * PERFORMANCE Group
   */
  {
    action: "READ",
    target: "CS_PERFORMANCE",
    clientPermissionIndex: 18,
    userPermissionIndex: 10,
  },
  /**
   * * CRM Group
   */
  {
    action: "READ",
    target: "CONTACT",
    clientPermissionIndex: 19,
    userPermissionIndex: 11,
  },
  {
    action: "CREATE",
    target: "CONTACT",
    clientPermissionIndex: 19,
    userPermissionIndex: 11,
  },
  {
    action: "UPDATE",
    target: "CONTACT",
    clientPermissionIndex: 19,
    userPermissionIndex: 11,
  },
  {
    action: "DELETE",
    target: "CONTACT",
    clientPermissionIndex: 19,
    userPermissionIndex: 11,
  },
  { action: "READ", target: "CUSTOM_FIELDS", commonAccess: true },
  { action: "CREATE", target: "CUSTOM_FIELDS", commonAccess: true },
  { action: "UPDATE", target: "CUSTOM_FIELDS", commonAccess: true },
  { action: "DELETE", target: "CUSTOM_FIELDS", commonAccess: true },
  /**
   * * CHAT CREDITS Group
   */
  {
    action: "READ",
    target: "CHAT_CREDITS",
    clientPermissionIndex: 20,
    userPermissionIndex: 12,
  },
  {
    action: "CREATE",
    target: "CHAT_CREDITS",
    clientPermissionIndex: 20,
    userPermissionIndex: 12,
  },
  {
    action: "UPDATE",
    target: "CHAT_CREDITS",
    clientPermissionIndex: 20,
    userPermissionIndex: 12,
  },
  {
    action: "DELETE",
    target: "CHAT_CREDITS",
    clientPermissionIndex: 20,
    userPermissionIndex: 12,
  },
  /**
   * * WALLETS Group
   */
  {
    action: "READ",
    target: "WALLET",
    allowedCountries: ["ID"],
    clientPermissionIndex: 21,
    userPermissionIndex: 13,
  },
  {
    action: "CREATE",
    target: "WALLET",
    allowedCountries: ["ID"],
    clientPermissionIndex: 21,
    userPermissionIndex: 13,
  },
  {
    action: "UPDATE",
    target: "WALLET",
    allowedCountries: ["ID"],
    clientPermissionIndex: 21,
    userPermissionIndex: 13,
  },
  {
    action: "DELETE",
    target: "WALLET",
    allowedCountries: ["ID"],
    clientPermissionIndex: 21,
    userPermissionIndex: 13,
  },
  {
    action: "READ",
    target: "HEADER_CHAT_CREDIT",
    clientPermissionIndex: 20,
    userPermissionIndex: 12,
  },
  /**
   * * SETTINGS Group
   */
  {
    action: "READ",
    target: "SETTING_STORE",
    clientPermissionIndex: 22,
    userPermissionIndex: 14,
  },
  {
    action: "CREATE",
    target: "SETTING_STORE",
    clientPermissionIndex: 22,
    userPermissionIndex: 14,
  },
  {
    action: "UPDATE",
    target: "SETTING_STORE",
    clientPermissionIndex: 22,
    userPermissionIndex: 14,
  },
  {
    action: "DELETE",
    target: "SETTING_STORE",
    clientPermissionIndex: 22,
    userPermissionIndex: 14,
  },
  {
    action: "READ",
    target: "SETTING_STORE_COURIER",
    clientPermissionIndex: 22,
    userPermissionIndex: 14,
  },
  {
    action: "READ",
    target: "SETTING_STORE_BANK",
    allowedCountries: ["ID"],
    clientPermissionIndex: 22,
    userPermissionIndex: 14,
  },
  { action: "READ", target: "CONVERSION_API", commonAccess: true },
  {
    action: "READ",
    target: "SETTING_ADMIN",
    clientPermissionIndex: 22,
    userPermissionIndex: 15,
  },
  {
    action: "CREATE",
    target: "SETTING_ADMIN",
    clientPermissionIndex: 22,
    userPermissionIndex: 15,
  },
  {
    action: "UPDATE",
    target: "SETTING_ADMIN",
    clientPermissionIndex: 22,
    userPermissionIndex: 15,
  },
  {
    action: "DELETE",
    target: "SETTING_ADMIN",
    clientPermissionIndex: 22,
    userPermissionIndex: 15,
  },
  { action: "READ", target: "FEATURES_ACCESS", commonAccess: true },
  { action: "CREATE", target: "FEATURES_ACCESS", commonAccess: true },
  { action: "UPDATE", target: "FEATURES_ACCESS", commonAccess: true },
  { action: "DELETE", target: "FEATURES_ACCESS", commonAccess: true },
  // ? Subscription page not shown on KKT NEO
  { action: "READ", target: "SETTING_ACCOUNT", commonAccess: true },
  { action: "CREATE", target: "SETTING_ACCOUNT", commonAccess: true },
  { action: "UPDATE", target: "SETTING_ACCOUNT", commonAccess: true },
  { action: "DELETE", target: "SETTING_ACCOUNT", commonAccess: true },

  /**
   * * LIVE CHAT
   */
  {
    action: "CREATE",
    target: "CREATE_LIVE_CHAT",
    userPermissionIndex: 26,
    clientPermissionIndex: 27,
  },
  {
    action: "READ",
    target: "CREATE_LIVE_CHAT",
    userPermissionIndex: 26,
    clientPermissionIndex: 27,
  },
  {
    action: "UPDATE",
    target: "EDIT_LIVE_CHAT",
    userPermissionIndex: 27,
    clientPermissionIndex: 27,
  },
  {
    action: "READ",
    target: "EDIT_LIVE_CHAT",
    userPermissionIndex: 27,
    clientPermissionIndex: 27,
  },
  {
    action: "READ",
    target: "CREATE_EMAIL",
    clientPermissionIndex: 24,
  },
  {
    action: "READ",
    target: "EDIT_EMAIL",
    clientPermissionIndex: 25,
  },
  {
    action: "READ",
    target: "VIEW_FEATURE",
    clientPermissionIndex: 28,
  },
  {
    action: "READ",
    target: "ADVANCE_SEARCH",
    clientPermissionIndex: 30,
  },

  { action: "READ", target: "GROUP_MANAGEMENT", commonAccess: true },
  { action: "CREATE", target: "GROUP_MANAGEMENT", commonAccess: true },
  { action: "UPDATE", target: "GROUP_MANAGEMENT", commonAccess: true },
  { action: "DELETE", target: "GROUP_MANAGEMENT", commonAccess: true },

  /**
   * * FORM Group
   */
  {
    action: "READ",
    target: "FORM",
    clientPermissionIndex: 26,
  },
  {
    action: "CREATE",
    target: "FORM",
    clientPermissionIndex: 26,
  },
  {
    action: "UPDATE",
    target: "FORM",
    clientPermissionIndex: 26,
  },
  {
    action: "DELETE",
    target: "FORM",
    clientPermissionIndex: 26,
  },
  /**
   * * ADVANCED_BROADCAST
   */
  {
    action: "READ",
    target: "ADVANCED_BROADCAST",
    clientPermissionIndex: 31,
  },
  /**
   * * EMAIL
   */
  {
    action: "READ",
    target: "EMAIL_TEMPLATE",
    clientPermissionIndex: 32,
    userPermissionIndex: 29,
  },
  {
    action: "READ",
    target: "EMAIL_BROADCAST",
    clientPermissionIndex: 32,
    userPermissionIndex: 30,
  },
];

const adminNegativePermissions: PermissionModel[] = [
  { action: "READ", target: "SETTING_STORE_BANK" },
  { action: "READ", target: "SETTING_STORE_SMART_CHAT" },
];

const customerServicePermissions: PermissionModel[] = [
  { action: "READ", target: "DASHBOARD" },
  { action: "READ", target: "PRODUCT", allowedCountries: ["ID"] },
  { action: "READ", target: "CREATE_PRODUCT", allowedCountries: ["ID"] },
  { action: "CREATE", target: "PRODUCT", allowedCountries: ["ID"] },
  { action: "ACTIVATE", target: "PRODUCT", allowedCountries: ["ID"] },
  { action: "UPDATE", target: "PRODUCT", allowedCountries: ["ID"] },
  { action: "DELETE", target: "PRODUCT", allowedCountries: ["ID"] },

  { action: "READ", target: "CATEGORY", allowedCountries: ["ID"] },
  { action: "CREATE", target: "CATEGORY", allowedCountries: ["ID"] },
  { action: "UPDATE", target: "CATEGORY", allowedCountries: ["ID"] },
  { action: "DELETE", target: "CATEGORY", allowedCountries: ["ID"] },

  { action: "READ", target: "TRANSACTION", allowedCountries: ["ID"] },
  { action: "CREATE", target: "TRANSACTION", allowedCountries: ["ID"] },
  { action: "UPDATE", target: "TRANSACTION", allowedCountries: ["ID"] },
  { action: "DELETE", target: "TRANSACTION", allowedCountries: ["ID"] },

  { action: "READ", target: "CALL_CENTER" },
  { action: "CREATE", target: "CALL_CENTER" },
  { action: "UPDATE", target: "CALL_CENTER" },
  { action: "DELETE", target: "CALL_CENTER" },

  { action: "READ", target: "CUSTOMER_SERVICE_INBOX" },
  { action: "CREATE", target: "CUSTOMER_SERVICE_INBOX" },
  { action: "UPDATE", target: "CUSTOMER_SERVICE_INBOX" },
  { action: "DELETE", target: "CUSTOMER_SERVICE_INBOX" },

  { action: "READ", target: "CUSTOMER_SERVICE_HISTORY" },
  { action: "CREATE", target: "CUSTOMER_SERVICE_HISTORY" },
  { action: "UPDATE", target: "CUSTOMER_SERVICE_HISTORY" },
  { action: "DELETE", target: "CUSTOMER_SERVICE_HISTORY" },

  { action: "CREATE", target: "CUSTOMER_SERVICE_TRANSACTION_BUTTON_CREATE" },
  { action: "READ", target: "FORM" },
];

export default function defineAbilityFor(abilityModel: AbilityDefinitionModel) {
  const data: AbilityDataModel = {
    allPermissions: allPermission,
    adminNegativePermissions: adminNegativePermissions,
    customerServicePermissions: customerServicePermissions,
  };

  return AbilityHelper.getInstance().attachAbility(data, abilityModel);
}
